<template>
    <b-overlay :show="showLoader">
        <b-card v-if="bill">
            <div :class="bill && bill.reversal_bill ? 'pb-2' : 'py-2'">
                <div class="row">
                    <div class="col-6 mb-2">
                        <b-badge v-if="bill && bill.reversal_bill" variant="danger">{{$t('reporting.issued_bills.reversed_bill')}}</b-badge>
                    </div>
                    <div class="col-6 mb-2">
                        <div class="float-right" style="display: inline-flex;">
                            <div class="mr-2">
                                <b-button variant="primary" @click="printBillToPDF">{{ $t('reporting.issued_bills.generate_pdf') }}</b-button>
                            </div>
                            <div>
                                <b-button variant="primary" @click="printBillToExcel">{{ $t('reporting.issued_bills.generate_excel') }}</b-button>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6 mt-2 mt-md-0">
                        <div class="billInfo data-border h-100">
                            <div class="border-text bgCard" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                {{$t('reporting.issued_bills.bill')}}
                                {{getbillId(bill)}}
                            </div>
                            <div class=" px-1 pt-1">
                                <div>
                                    {{$t('reporting.issued_bills.bill_issued_at')}} {{ dayjs(bill.timestamp).format("DD.MM.YYYY ob H:mm:ss") }}
                                </div>
                                <div v-if="bill && bill.table && bill.table.room_name">
                                    {{$t('reporting.issued_bills.work_space')}}: {{ bill.table.room_name }}, {{ bill.table.table_name }}
                                </div>
                                <div>
                                    {{$t('reporting.issued_bills.waiter')}}: {{ bill.employee.name }}
                                </div>
                                <div v-if="bill && bill.bill_note && (bill.bill_note.description.length > 0 || bill.bill_note.title.length > 0)">
                                    {{$t('reporting.issued_bills.bill_note')}}: {{ bill.bill_note.title }}
                                    <span v-if="bill.bill_note.description.length > 0 && bill.bill_note.title.length > 0">|</span>
                                    {{ bill.bill_note.description }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="billInfo data-border h-100"
                             v-if="bill.cash_register && bill.cash_register.business_unit">
                            <div class="border-text bgCard">
                                {{$t('general.business_unit')}}:
                            </div>
                            <div class="px-1 pt-1">
                                <div>
                                    {{ bill.cash_register.business_unit.name }}
                                </div>
                                <div>
                                    {{ bill.cash_register.business_unit.street }}
                                    {{ bill.cash_register.business_unit.house_number }}
                                    {{ bill.cash_register.business_unit.house_number_additional }}
                                </div>
                                <div>
                                    {{ bill.cash_register.business_unit.postal_code }}
                                    {{ bill.cash_register.business_unit.city }}
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="col-12 mt-3" v-if="bill.reversal_bill && bill.reversal_bill_reason.length > 0">
                        <div class="billInfo data-border h-100">
                            <div class="border-text bgCard">
                                {{$t('reporting.issued_bills.reversal_reason')}}
                            </div>
                            <div class="px-1 pt-1">
                                <div>
                                    {{bill.reversal_bill_reason }}
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="col-12 mt-2" v-if="bill.buyer">
                        <div class="row d-flex justify-content-center">
                            <div class="col-12 col-md-6 mt-2 mt-md-0">
                                <div class="billInfo data-border h-100">
                                    <div class="border-text bgCard">
                                        {{$t('general.buyer')}}:
                                    </div>
                                    <div class=" px-1 pt-1">
                                        <div>
                                            {{ bill.buyer.name }}
                                        </div>
                                        <div>
                                            {{ bill.buyer.address }}
                                        </div>
                                        <div>
                                            {{ bill.buyer.country }}
                                        </div>
                                        <div>
                                            <span v-if="bill.buyer.type === Buyer.TaxPayer || bill.buyer.type === Buyer.Foreign">
                                                {{$t('reporting.issued_bills.tax_id')}}:
                                            </span>
                                            <span v-else>
                                                {{$t('reporting.issued_bills.tax_number')}}:
                                            </span>
                                            {{ bill.buyer.tax_number }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <div v-if="billHistory && billHistory.length > 0">
                <div class="title mt-2">
                    {{$t('reporting.issued_bills.bill_history')}}
                </div>
                <Table name="bill_history" ref="billHistory" class="mt-1" :columnDefs="columnDefsBillHistory" :rowData="billHistory" />

            </div>


            <div class="d-flex justify-content-between align-items-center mt-2">
                <div class="title">
                    {{$t('general.payment_types')}}
                </div>
                <div v-if="$hasPermission($permissions.ReportingWrite)">
                    <b-button variant="primary" v-if="!bill.reversal_bill && !bill.parent_id && (billHistory && billHistory.length === 0)" @click="$refs.editPaymentModal.show(bill)">{{ $t('reporting.issued_bills.edit_payment_methods') }}</b-button>
                </div>
            </div>
            <Table name="payment_types" v-if="bill" class="mt-1" :columnDefs="paymentTypesDef" :rowData="bill.payment_types"/>

            <div class="title mt-2">
                {{$t('reporting.issued_bills.products')}}
            </div>
            <Table name="products" ref="products" v-if="bill" class="mt-1" :columnDefs="columnDefs" :rowData="bill.products" />

            <div v-if="is_tax_payer" class="title mt-2">
                {{$t('reporting.issued_bills.tax_price')}}
            </div>

            <Table name="bill_details" v-if="bill && is_tax_payer" class="mt-1" :columnDefs="billDetails" :rowData="bill.vats"/>

            <div class="d-flex justify-content-center w-100"
                 v-if="bill.payment_types && bill.payment_types.length > 0 && bill.payment_types[0].tax_certification">
                <div class="data-border mt-4" style="width: 60%">
                    <div class="border-text bgCard">
                        {{$t('reporting.issued_bills.furs_confirmation')}}
                    </div>
                    <div class="p-1">
                        <div class="text-center" style="font-size: 18px;" v-if="bill">
                            <b>{{$t('reporting.issued_bills.zoi')}}:</b> {{ bill.zoi }}
                        </div>
                        <div class="text-center" style="font-size: 18px;">
                            <b>{{$t('reporting.issued_bills.eor')}}:</b>
                            <span v-if="bill.eor">
                                {{ bill.eor }}
                            </span>
                            <span v-else>
                                {{$t('reporting.issued_bills.not_tax_confirmed')}}
                            </span>
                        </div>
                        <div class="d-flex justify-content-center w-100">
                            <img alt="QR code img" style="height: 150px; width: 150px;" :src="this.qr_code"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center w-100">
                <div class="data-border mt-4" style="width: 60%">
                    <div class="border-text bgCard">
                        {{$t('reporting.issued_bills.bill_total_price')}}
                    </div>
                    <div class="p-2" style="font-size: 18px;" v-if="bill">
                        <div class="row">
                            <div class="col-12 col-md-6 textPriceInfo">
                                {{$t('reporting.issued_bills.total')}}:
                            </div>
                            <div class="col-12 col-md-6 text-right textPrice">
                                {{ $numberRounding(bill.total_price) }}€
                            </div>

                            <div v-if="is_tax_payer" class="col-12 col-md-6 textPriceInfo">
                                {{$t('reporting.issued_bills.total_price_without_vat')}}:
                            </div>
                            <div v-if="is_tax_payer" class="col-12 col-md-6 text-right textPrice">
                                {{ $numberRounding(bill.total_price_without_vat) }}€
                            </div>

                            <div v-if="is_tax_payer" class="col-12 col-md-6 textPriceInfo">
                                {{$t('reporting.issued_bills.tax')}}:
                            </div>
                            <div v-if="is_tax_payer" class="col-12 col-md-6 text-right textPrice">
                                {{ $numberRounding(bill.total_price - bill.total_price_without_vat) }}€
                            </div>

                            <div class="col-12 col-md-6 textPriceInfo">
                                {{$t('general.discount_value')}}:
                            </div>
                            <div class="col-12 col-md-6 text-right textPrice">
                                {{ $numberRounding(bill.discount_value) }}€
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <bill-edit-payment-methods-modal ref="editPaymentModal" v-on:payment-changed="loadData"/>
        </b-card>
    </b-overlay>
</template>

<script>
    import {BCard, BButton, BBadge, BOverlay} from 'bootstrap-vue'
    import Table from '@/views/components/Table/Table'
    import BillEditPaymentMethodsModal from '@/views/Bills/Components/BillEditPaymentMethodsModal'
    import { Buyer } from '@/libs/enums/Buyer'

    export default {
        computed: {
            Buyer() {
                return Buyer
            }
        },
        components: {BCard, BBadge, Table, BButton, BOverlay, BillEditPaymentMethodsModal},
        data() {
            return {
                showLoader: false,
                billHistory: [],
                bill_id: '',
                bill: null,
                is_tax_payer: true,
                qr_code: '',
                is_loaded: false,
                productSubcategories: [],
                columnDefsBillHistory: [
                    {headerName: this.$t('table_fields.bill_identifier'), field: 'joined_bill_identifier', filter: false, floatingFilter: false, editable: false},
                    {headerName: this.$t('table_fields.date'), field: 'created_at', filter: false, floatingFilter: false, editable: false, cellRenderer: 'DisplayBillHistoryDate', cellRendererParams: (params) => ({ type: params.data.type, created_at: params.data.created_at, timestamp: params.data.timestamp })},
                    {headerName: this.$t('table_fields.issued_by'), field: 'issued_by.name', filter: false, floatingFilter: false, editable: false},
                    {headerName: this.$t('table_fields.amount_type'), field: 'type', filter: false, floatingFilter: false, editable: false, cellRenderer: 'DisplayIssuedBillHistoryType'},
                    {headerName: this.$t('table_fields.price'), field: 'total_price', filter: false, floatingFilter: false, editable: false, cellRenderer: 'DisplayPrice'},
                    {headerName: this.$t('table_fields.actions'), editable: false, filter: false, floatingFilter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', minWidth: 200, cellRendererParams: {button: 'ViewBill'}}
                ],
                columnDefs: [
                    {headerName: this.$t('table_fields.name'), field: 'name', filter: false, floatingFilter: false, editable: false},
                    {
                        headerName: this.$t('table_fields.subcategory'),
                        field: 'product_category_id',
                        filter: false,
                        floatingFilter: false,
                        editable: false,
                        cellRenderer: (params) => this.getCategoryName(params)
                    },
                    {
                        headerName: this.$t('table_fields.price_per_unit'),
                        field: 'price_per_item',
                        filter: false,
                        floatingFilter: false,
                        editable: false,
                        cellRenderer: 'DisplayPrice'
                    },
                    {
                        headerName: this.$t('table_fields.quantity'),
                        field: 'quantity',
                        filter: false,
                        floatingFilter: false,
                        editable: false,
                        cellRenderer: (params) => this.getQuantity(params.value)
                    },
                    {
                        headerName: this.$t('table_fields.discount'),
                        field: 'discount',
                        filter: false,
                        floatingFilter: false,
                        editable: false,
                        cellRenderer: (params) => this.getDiscountValue(params)
                    },
                    {headerName: this.$t('table_fields.price'), field: 'total_price', filter: false, floatingFilter: false, editable: false, cellRenderer: 'DisplayPrice'},
                    {
                        headerName: this.$t('table_fields.tax_rate'),
                        field: 'vat_group.percentage',
                        filter: false,
                        floatingFilter: false,
                        editable: false,
                        cellRenderer: 'DisplayPercent'
                    }
                ],
                paymentTypesDef: [
                    {headerName: this.$t('table_fields.name'), field: 'name', filter: false, floatingFilter: false, editable: false},
                    {headerName: this.$t('table_fields.price'), field: 'total_price', filter: false, floatingFilter: false, editable: false, cellRenderer: 'DisplayPrice'}
                ],
                billDetails: [
                    {headerName: this.$t('table_fields.tax_rate'), field: 'rate', filter: false, floatingFilter: false, editable: false, cellRenderer: 'DisplayPercent'},
                    {headerName: this.$t('table_fields.net_price'), field: 'net_price', filter: false, floatingFilter: false, editable: false, cellRenderer: 'DisplayPrice'},
                    {headerName: this.$t('table_fields.vat'), field: 'value', filter: false, floatingFilter: false, editable: false, cellRenderer: 'DisplayPrice' },
                    {headerName: this.$t('table_fields.gross_price'), field: 'gross_price', filter: false, floatingFilter: false, editable: false, cellRenderer: 'DisplayPrice'}

                ]
            }
        },
        methods: {
            async printBillToPDF() {
                try {
                    this.showLoader = true

                    const apiUrl = `/api/reports/v1/bill/${this.bill.uuid}/pdf`

                    const response = await this.$http({
                        url: apiUrl,
                        method: 'GET',
                        responseType: 'blob' // important
                    })

                    const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))

                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('target', '_blank')
                    link.setAttribute('download', `Racun-${this.bill.uuid}.pdf`)
                    document.body.appendChild(link)
                    link.click()
                } catch (error) {
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.is_loaded = true
                    this.showLoader = false
                }
            },
            async printBillToExcel() {
                try {
                    this.showLoader = true

                    const apiUrl = `/api/reports/v1/bill/${this.bill.uuid}/xlsx`

                    const response = await this.$http({
                        url: apiUrl,
                        method: 'GET',
                        responseType: 'blob' // important
                    })

                    const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/xls'}))

                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('target', '_blank')
                    link.setAttribute('download', `Racun-${this.bill.uuid}.xlsx`)
                    document.body.appendChild(link)
                    link.click()
                } catch (error) {
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.is_loaded = true
                    this.showLoader = false
                }
            },
            getCategoryName(value) {
                if (value && value.data && value.data.product_category) {
                    const item = this.productSubcategories.find(x => x.id === value.data.product_category.id)
                    if (item) return item.name
                }
                return '/'
            },
            getQuantity(value) {
                if (value) {
                    return `${Number(value / 100).toFixed(2).replace('.00', '')}x`
                }

                return 0
            },
            getDiscountValue(value) {
                if (value && value.data) {
                    return `${this.$numberRounding(value.data.total_price_without_discount - value.data.total_price)} €`
                }
                return 0.00
            },
            getbillId(item) {

                if (!item) {
                    return '/'
                }

                if (item.custom_numbering_id && item.custom_numbering_id !== '') return `${item.custom_numbering_id}-${item.bill_busines_unit_id}-${item.bill_cash_register_id}-${item.bill_identifier}`
                else return `${item.bill_busines_unit_id}-${item.bill_cash_register_id}-${item.bill_identifier}`

            },

            async loadData() {
                try {
                    this.showLoader = true
                    this.is_loaded = false

                    const response1 = await this.$http.get(`/api/client/v1/issued_bills/${this.bill_id}`)
                    const response2 = this.$http.get('/api/client/v1/product_subcategories/')
                    const response3 = await this.$http.get(`/api/client/v1/issued_bills/qr_code/${this.bill_id}`)
                    const response4 = await this.$http.get(`/api/client/v1/issued_bills/get_bill_history/${this.bill_id}`)


                    const responses = await Promise.all([response1, response2, response3, response4])

                    this.bill = responses[0].data ?? {}
                    this.productSubcategories = responses[1].data ?? {}
                    this.qr_code = responses[2].data.qr_code
                    this.billHistory = responses[3].data ?? []

                } catch (error) {
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.is_loaded = true
                    this.showLoader = false
                }
            }
        },
        async mounted() {
            this.bill_id = this.$route.params.bill_id
            await this.loadData()
            this.is_tax_payer = this.$store.getters['user/selected_client'].is_tax_payer
            this.$refs.products.gridOptions.columnApi.setColumnVisible('vat_group.percentage', this.is_tax_payer)
        },
        watch:{
            async '$route.params.bill_id'(val) {
                this.bill_id = val
                await this.loadData()

                this.$refs.products.gridOptions.columnApi.setColumnVisible('vat_group.percentage', this.is_tax_payer)

                const rootElement = document.documentElement
                if (rootElement) {
                    rootElement.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })
                }
            }
        }
    }
</script>

<style scoped>
.billInfo {
  font-size: 18px;
}

.textPriceInfo {
  font-size: 20px;
}

.textPrice {
  font-weight: bold;
  font-size: 20px;
}

.title {
  font-weight: bold;
  font-size: 17px;
}

.data-border {
  border: 1px solid #646464;
  margin: 5px;
  padding: 10px;
  position: relative;
  border-radius: 18px;
}

.border-text {
  position: absolute;
  top: -12px;
  left: 20px;
  background: white;
  padding: 0 15px;
  font-weight: bold;

}
</style>
